








import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import SessionStore from '@state/modules/session'
import DashboardNavigation from '@components/dashboard/dashboard-navigation.vue'

@Component({
  components: {
    DashboardNavigation,
  },
})
export default class Dashboard extends Vue {
  @Prop({ required: false }) enrollmentError!: boolean

  created() {
    if (this.$launchDarkly.get('svelte-learner-experience', false)) {
      window.location.href = '/lx/'
    }
    if (this.enrollmentError) {
      // @ts-ignore
      this.$snotify.error(this.$t('dashboard.enrollmentError'))
    }
  }
}
