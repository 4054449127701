import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '../store'
import {
  Session as apiSession,
  Profile as apiProfile,
  Messages as apiMessages,
  MyDashboard as apiMyDashboard,
} from '@/src/state/api'
import { setLocale } from '@src/locale'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import {
  User,
  PartnerAdmin,
  SiteAdmin,
  UserStatistics,
  LearnerMessage,
  MessagesCampaignRecipientStatuses,
  DeviceDetails,
  LeaderboardTypes,
} from '../api/graphql'
import Message from '@src/models/message'
import { PendoSetup } from '@/src/plugins/pendo'
import VueCookies from 'vue-cookie'

@Module({
  namespaced: true,
  store: store,
  dynamic: true,
  name: 'session',
})
class Session extends VuexModule {
  currentUser: User | null = null
  currentImpersonatingUser: User | null = null
  currentPartnerAdmin: PartnerAdmin | null = null
  currentAdmin: SiteAdmin | null = null
  statistics: UserStatistics | null = null
  statisticsUpdatedAt: Date | null = null
  messages: Message[] = []

  get mobileAppEnabled() {
    return this.currentUser?.mobileAppEnabled || VueCookies.get('kb4-learner-app-demo')
  }

  get accountDefaultLanguage() {
    return this.currentUser?.accountLanguageSettings?.trainingLocale
  }

  get userLanguageSetting() {
    return this.currentUser?.languageSettings?.trainingLocale
  }

  get initialized() {
    return !isEmpty(this.currentUser)
  }

  get motionReduced() {
    return this.currentUser?.popcornTheaterReduceMotion
  }

  get disableTour() {
    return this.currentUser?.lxDisableTour
  }

  get allowAnalytics() {
    return this.currentUser?.allowAnalytics
  }

  get dashboardEnabled(): boolean {
    return this.myDashboardEnabled || this.teamDashboardEnabled
  }

  get myDashboardEnabled(): boolean {
    return !!this.currentUser?.statistics?.id
  }

  get messagesEnabled(): boolean {
    return !!this.currentUser?.messagesEnabled
  }

  get dashboardStatistics() {
    return this.statistics
  }

  get teamDashboardEnabled() {
    return (
      !!this.currentUser?.managerTrainingEnabled &&
      !!this.currentUser?.managerReport?.totalMembersCount
    )
  }

  get managerPhishingEnabled() {
    return !!this.currentUser?.managerPhishingEnabled
  }

  get managerRiskScoreEnabled() {
    return !!this.currentUser?.managerRiskScoreEnabled
  }

  get managerUserInfoEnabled() {
    return !!this.currentUser?.managerUserInfoEnabled
  }

  get managerTotalDirectReports(): number {
    return this.currentUser?.managerReport?.totalMembersCount || 0
  }

  get optionalTrainingEnabled() {
    return this.currentUser?.optionalTrainingEnabled
  }

  get leaderboardEnabled() {
    return (this.currentUser?.gamification as any)?.leaderboards?.some(
      leaderboard => {
        return leaderboard.leaders?.some(l => l.includesCurrentUser)
      }
    )
  }

  get aidaOptionalTrainingEnabled() {
    return this.currentUser?.aidaOptionalTrainingEnabled
  }

  get userHasSelectedLanguage() {
    return !!this.userLanguageSetting
  }

  get trainingLanguagePreferences() {
    return uniq(
      compact([
        this.currentUser?.languageSettings?.trainingLocale,
        ...(this.currentUser?.enrollmentLanguageSelections || []),
        this.currentUser?.accountLanguageSettings?.trainingLocale,
      ]),
    )
  }

  get unreadMessagesCount() {
    return this.unreadMessages.length
  }

  get readMessages() {
    return this.messages.filter(
      message => message.status === MessagesCampaignRecipientStatuses.Read,
    )
  }

  get unreadMessages() {
    return this.messages.filter(
      message => message.status !== MessagesCampaignRecipientStatuses.Read,
    )
  }

  get customHelpEnabled() {
    return this.currentUser?.customHelpEnabled
  }

  get customHelpUrl() {
    return this.currentUser?.customHelpUrl
  }

  get badgesEnabled() {
    return this.currentUser?.gamification?.badgesEnabled
  }

  get passlessEnabled() {
    return this.currentUser?.passlessEnabled
  }

  get samlEnabled() {
    return this.currentUser?.samlEnabled
  }

  get disableNonSamlLogins() {
    return this.currentUser?.disableNonSamlLogins
  }

  get riskScoreV2Enabled() {
    return this.currentUser?.riskScoreV2Enabled
  }

    // Mutations
  @Mutation
  setCurrentUser(newSession: User) {
    this.currentUser = newSession
    const lang = newSession?.languageSettings?.trainingLocale || newSession?.accountLanguageSettings?.trainingLocale
    if (lang) {
      setLocale({ code: lang })
    }
  }

  @Mutation
  setDeviceLinkToken(token: string) {
    if (this.currentUser) {
      this.currentUser.deviceLinkToken = token
    }
  }

  @Mutation
  setCurrentAdmin(admin: SiteAdmin) {
    this.currentAdmin = admin
  }

  @Mutation
  setCurrentPartnerAdmin(partnerAdmin: PartnerAdmin) {
    this.currentPartnerAdmin = partnerAdmin
  }

  @Mutation
  setCurrentImpersonatingUser(user: User) {
    this.currentImpersonatingUser = user
  }

  @Mutation
  storeStatistics(data: UserStatistics) {
    this.statisticsUpdatedAt = new Date()
    this.statistics = data
  }

  @Mutation
  markMessageAsRead(learnerMessage: LearnerMessage) {
    const messageIndex = this.messages.findIndex(
      message => message.id === learnerMessage.id,
    )
    if (messageIndex !== -1) {
      this.messages[messageIndex].status = learnerMessage.status
    }
  }

  @Mutation
  removeUnlinkedDevice(deviceId: String) {
    const i = this.currentUser?.linkedDevices?.findIndex(
      d => d.deviceId === deviceId,
    )
    if (i !== null && i !== -1) {
      this.currentUser!.linkedDevices!.splice(i!, 1)
    }
  }

  @Mutation
  setMessages(messages: LearnerMessage[]) {
    if (!messages) return
    this.messages = messages.map(message => new Message(message))
  }

  @Mutation
  setPendo() {
    if (this.currentUser?.allowAnalytics) {
      PendoSetup({
        user: this.currentUser,
        admin: this.currentAdmin,
        reseller: this.currentPartnerAdmin
      })
    }
  }

  // Actions
  @Action({ rawError: true })
  async fetch() {
    const userSession = await apiSession.userSession()
    if (userSession) {
      this.context.commit('setCurrentUser', userSession.data?.data?.currentUser)
      this.context.commit('setMessages', userSession.data?.data?.currentUser.learnerMessages)
      this.context.commit('setCurrentImpersonatingUser', userSession.data?.data?.currentImpersonatingUser)
      this.context.commit('setCurrentPartnerAdmin', userSession.data?.data?.currentPartnerAdmin)
      this.context.commit('setCurrentAdmin', userSession.data?.data?.currentAdmin)
      this.context.commit('setPendo')
    }
  }

  @Action
  async refreshDeviceLinkToken() {
    const response = await apiProfile.deviceLinkToken()
    this.context.commit(
      'setDeviceLinkToken',
      response.data?.data?.currentUser.deviceLinkToken,
    )
  }

  @Action
  async fetchDashboardStatistics() {
    if (apiMyDashboard.statisticsRecentlyFetched) {
      return Promise.resolve()
    }
    const response = await apiMyDashboard.dashboardStatistics()
    this.context.commit(
      'storeStatistics',
      response.data?.data?.currentUser.statistics,
    )
  }

  @Action
  async updateUserLanguage(language) {
    const response = await apiProfile.updateUserProfile({
      languageSettings: { trainingLocale: language.code },
    })
    this.context.commit(
      'setCurrentUser',
      response.data?.data?.userProfileEdit.node,
    )
  }

  @Action
  async unlinkDevice(device: DeviceDetails) {
    await apiProfile.deleteDeviceDetails(device.deviceId)
    this.context.commit('removeUnlinkedDevice', device.deviceId)
  }

  @Action
  async markLearnerMessageRead(messageId: number) {
    const response = await apiMessages.markMessageRead({
      id: messageId,
      attributes: {
        status: MessagesCampaignRecipientStatuses.Read,
      },
    })
    this.context.commit(
      'markMessageAsRead',
      response.data?.data?.updateMessageRecipient.node,
    )
  }
}

export default getModule(Session)
